@import '../../theme';

.cx-page {
  padding-bottom: 120px;
}

.cx-page-header {
  background-color: var(--cx-color-background);
  text-align: center;
  padding: 25px 0;
}

.cx-page-title {
  @include type('4');
  font-weight: var(--cx-font-weight-semi);
  margin: 0 auto;
}

.cx-page-section {
  padding-top: 60px;
}
