%QuoteDetailsPageTemplate {
  max-width: var(--cx-page-width-max);
  padding: 0 1.5rem;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  margin-block-start: 2rem;

  .BodyContent {
    --cx-flex-basis: 70%;
    flex-direction: column;
    flex-wrap: nowrap;

    @include media-breakpoint-down(md) {
      --cx-flex-basis: 100%;
    }
  }

  .CenterRightContent {
    --cx-flex-basis: 30%;
    flex-direction: column;
    justify-content: flex-start;

    @include media-breakpoint-up(lg) {
      padding-inline-start: 1rem;
      padding-block-start: 9rem;
      padding-block-end: 1rem;
    }

    @include media-breakpoint-up(lg) {
      > * {
        flex: none;
      }
    }

    @include media-breakpoint-down(md) {
      --cx-flex-basis: 50%;
      justify-content: flex-end;
      flex-direction: row;
      margin: 2rem 0;
    }

    @include media-breakpoint-down(sm) {
      --cx-flex-basis: 100%;
      flex-direction: unset;
    }
  }
}
