%cx-payment-type {
  @include checkout-media-style();
  border: 1px solid var(--cx-color-medium);
  border-radius: 10px;
  margin-top: 32px;
  margin-bottom: 30px;
  padding-inline-start: 1.875rem;
  padding-inline-end: 1.875rem;
  padding-top: 1.875rem;

  @include media-breakpoint-down(md) {
    padding-top: 20px;
  }

  .cx-payment-type-container {
    padding-top: 20px;
  }

  .cx-payment-type-label {
    margin: 0 auto 0 0.75rem;

    .cx-payment-type {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
