cx-saved-cart-list {
  .cx-saved-cart-list-header {
    padding: 3rem 0 1rem 0;
  }

  .cx-saved-cart-list-table {
    padding: 1.5rem 0 1.125rem 0;
    margin-bottom: 0;
    border-width: 1px 0 1px 0;
    border-color: var(--cx-color-light);
    border-style: solid;

    .cx-saved-cart-list-th-qty {
      width: 150px;

      @include media-breakpoint-up(md) {
        text-align: center;
      }
    }

    .cx-saved-cart-list-th-total {
      @include media-breakpoint-up(md) {
        text-align: end;
      }
    }

    .cx-table-label-mobile {
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    tr {
      width: 100%;
      &:hover {
        background-color: var(--cx-color-background);
      }

      @include media-breakpoint-down(md) {
        border-width: 1px 0 0 0;
        border-color: var(--cx-color-light);
        border-style: solid;

        &:first-child {
          border-width: 0;
          padding: 1.25rem 0 0 0;
        }
      }
    }

    th {
      padding: 1rem 0;
      text-align: start;

      &:first-child {
        padding-inline-start: 20px;
      }

      &:last-child {
        text-align: end;
        padding-inline-end: 20px;
      }
    }

    td {
      padding-top: 1.6875rem;
      padding-inline-end: 0.5rem;
      padding-bottom: 1.75rem;
      padding-inline-start: 0;

      @include media-breakpoint-up(md) {
        text-align: start;

        &:last-child {
          text-align: end;
          line-height: var(--cx-line-height);
          padding-inline-end: 20px;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        display: flex;
        border-width: 0;
        padding: 0 1.25rem;

        &:first-child {
          padding-top: 1.25rem;
        }

        &:last-child {
          padding-top: 1rem;
          padding-bottom: 1.25rem;
        }
      }
    }

    .cx-saved-cart-list-thead-mobile {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    .cx-saved-cart-list-cart-name {
      padding-inline-start: 20px;

      @include media-breakpoint-up(lg) {
        width: 20%;
      }

      .cx-saved-cart-list-value {
        text-decoration: underline;
        word-break: break-word;
      }
    }

    .cx-saved-cart-list-quantity {
      @include media-breakpoint-up(lg) {
        text-align: center;
      }
    }

    .cx-saved-cart-list-total {
      @include media-breakpoint-up(lg) {
        text-align: end;
      }
    }

    .cx-saved-cart-list-label {
      color: var(--cx-color-secondary);

      // TODO: (CXSPA-5955) Remove feature flag next major
      @include forFeature('a11ySavedCartsZoom') {
        text-align: start;
      }

      @include media-breakpoint-down(md) {
        font-size: var(--cx-font-size, 0.875rem);
        font-weight: var(--cx-font-weight-bold);
        min-width: 140px;
      }
    }

    .cx-saved-cart-list-value {
      color: var(--cx-color-text);

      @include media-breakpoint-down(md) {
        font-size: var(--cx-font-size, 1rem);
        font-weight: $font-weight-normal;
      }
    }
  }

  .cx-saved-cart-list-no-saved-carts {
    font-size: var(--cx-font-size, 1rem);
    font-weight: $font-weight-normal;
    min-height: auto;

    @include media-breakpoint-down(md) {
      padding-inline-start: 1.25rem;
      padding-inline-end: 1.25rem;
      min-height: auto;
    }
  }

  // TODO: (CXSPA-5955) Remove feature flag next major
  @include forFeature('a11ySavedCartsZoom') {
    @include media-breakpoint-down(md) {
      .cx-saved-cart-make-active {
        min-height: unset;
        padding: 0;
      }
    }
  }
}
