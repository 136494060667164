a {
  color: var(--cx-color-primary);

  &:hover {
    color: var(--cx-color-primary);
  }

  &.disabled {
    pointer-events: var(--cx-pointer-event, none !important);
    cursor: var(--cx-cursor, not-allowed !important);
    opacity: 0.8;
  }

  @include media-breakpoint-down(sm) {
    min-height: 48px;
    min-width: 48px;
    display: block;
  }
}

.cx-action-link {
  @include type('6');
  text-transform: var(--cx-button-text-transform);
  text-decoration: underline;
  cursor: pointer;
  display: inline-block;
  color: var(--cx-color-primary);
  &:hover {
    color: var(--cx-color-primary-accent);
    text-decoration: none;
  }
}
