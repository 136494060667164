%cx-my-account-v2-password {
  @media (min-width: 575px) {
    --cx-max-width: 50%;
  }
  .myaccount-password-header {
    @include type('3');
    padding-bottom: 1.5rem;
  }

  .myaccount-password-button {
    @include type();
    padding-top: 0.8rem;
    padding-inline-end: 1.5rem;
    padding-bottom: 0.8rem;
    padding-inline-start: 1.5rem;
  }

  .myaccount-password-button-cancel {
    color: var(--cx-color-primary);
  }

  .myaccount-password-label {
    @include type();
    min-width: 7.5rem;
    padding-top: 0;
    padding-inline-end: 1.2rem;
    padding-bottom: 0;
    padding-inline-start: 0;
  }

  .myaccount-label-padding {
    padding-bottom: 0.75rem;
  }

  .password-btn-group {
    display: flex;
    justify-content: end;
    flex-wrap: wrap;
  }

  .cx-message-info {
    @include type('7');
    border-color: var(--cx-color-visual-focus);
    background-color: var(--cx-color-info-accent);
    .cx-message-icon {
      cx-icon {
        color: var(--cx-color-primary-accent);
      }
    }
  }
}
