%VariantConfigurationOverviewTemplate {
  @include cx-configurator-template();
}

%VariantConfigurationOverviewTemplate {
  cx-page-slot.VariantConfigOverviewBottombar {
    cx-configurator-add-to-cart-button {
      z-index: 10;
    }
  }

  cx-page-slot.VariantConfigOverviewContent,
  cx-page-slot.VariantConfigOverviewNavigation {
    height: fit-content;
  }

  @include media-breakpoint-up(lg) {
    cx-page-slot.VariantConfigOverviewNavigation {
      max-inline-size: 30%;
    }

    cx-page-slot.VariantConfigOverviewContent {
      max-inline-size: 70%;
    }
  }

  @include media-breakpoint-up(xl) {
    cx-page-slot.VariantConfigOverviewNavigation {
      max-inline-size: calc(1140px * 0.3);
    }

    cx-page-slot.VariantConfigOverviewContent {
      max-inline-size: calc(1140px * 0.7);
    }
  }
}
