// SANTORINI v0.1
// This file is for theme configuration. These variables are used in global and component CSS files.
//
// You can:
//   1) Set new values for Bootstrap variables - https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss
//   2) Set new values for cxbase variables - cxbase/_variables.scss
//   3) Set new values for component variables - app/__/_.scss
// You cannot:
//   1) Add new variables
@import '../../cxbase/functions';

//fonts (see _fonts.scss to import)
$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont,
  'Segoe UI', 'Roboto', 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji',
  'Segoe UI Emoji', 'Segoe UI Symbol';

// TODO: Replace `$font-url` with swap url in 5.0 to use swap strategy by default.
// $font-url: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap' !default;
$font-url: 'https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700' !default;

// change theme-colors here
$primary: #1f7bc0 !default;
$primary-accent: #055f9f !default;
$secondary: #6c7079 !default;
$success: #38871f !default;
$success-accent: #f0fbe4 !default;
$danger: #db0002 !default;
$danger-accent: #fff1f1 !default;
$warning: #ffc107 !default;
$warning-accent: #fff5df !default;
$info: #17a2b8 !default;
$info-accent: #deeffe !default;
$light: #f1f1f1 !default;
$medium: #d3d6db !default;
$dark: #6c7079 !default;
$background: #f4f4f4 !default;
$background-dark: #212738 !default;
$inverse: #ffffff !default;
$text: #14293a !default;
$transparent: transparent !default;
$visual-focus: #6d9df7 !default;
$background-focus: rgba(80, 176, 244, 0.1);
$border-focus: rgba(166, 204, 232, 0.6);

$theme-colors: (
  'primary': $primary,
  'primary-accent': $primary-accent,
  'secondary': $secondary,
  'success': $success,
  'success-accent': $success-accent,
  'danger': $danger,
  'danger-accent': $danger-accent,
  'warning': $warning,
  'warning-accent': $warning-accent,
  'info': $info,
  'info-accent': $info-accent,
  'light': $light,
  'medium': $medium,
  'dark': $dark,
  'background': $background,
  'background-dark': $background-dark,
  'inverse': $inverse,
  'text': $text,
  'transparent': $transparent,
  'visual-focus': $visual-focus,
  'background-focus': $background-focus,
  'border-focus': $border-focus,
);

//type — https://github.com/twbs/bootstrap/blob/v4-dev/scss/_variables.scss#L225
$font-size-base: 1rem !default; //16px
// $h1-font-size: $font-size-base * 2.375; //38px
$h1-font-size: $font-size-base * 1.85; //36px
$h2-font-size: $font-size-base * 1.75; //28px
$h3-font-size: $font-size-base * 1.375; //22px
$h4-font-size: $font-size-base * 1.125; //18px
$h5-font-size: $font-size-base; //16px
$small-font-size: $font-size-base * 0.875; //14px

$font-weight-light: 'light';
$font-weight-normal: 'normal';
$font-weight-semi: 'semi';
$font-weight-bold: 'bold';

//update type size and weights
$type: (
  '1': $h1-font-size $font-weight-normal,
  '2': $h2-font-size $font-weight-semi,
  '3': $h3-font-size $font-weight-semi,
  '4': $h4-font-size $font-weight-bold,
  '5': $h5-font-size $font-weight-bold,
  '6': $h5-font-size $font-weight-semi,
  '7': $small-font-size $font-weight-normal,
  '8': $small-font-size $font-weight-bold,
);

//bring in variable defaults
@import '../../cxbase/variables';

// GLOBAL VARIABLE OVERRIDES
// These can be set with anything above or any variables exposed in Bootsrap or cxbase
$grid-gutter-width: 30px;

$line-height-base: 1.6;
$headings-line-height: 1.222222222222222;

$paragraph-margin-bottom: 1.5rem;
$border-radius: 0.25rem;

$btn-padding-y: 14px;

$input-height: 48px;
$input-btn-focus-box-shadow: none;
$input-padding-y: 0.6875rem;
$custom-select-padding-y: $input-padding-y;
$label-margin-bottom: 0.375rem;
$alert-padding-y: 20px;
$alert-border-radius: 0;

$input-placeholder-color: lighten($body-color, 50);
$input-border-color: theme-color('light');
$input-focus-border-color: theme-color('dark');
$input-focus-bg: theme-color('background');

$custom-select-bg: color('white');
$custom-select-border-color: $input-border-color;
$body-color: $text;
$body-bg: $inverse;

//remove variables
$colors: map-remove(
  $colors,
  'indigo',
  'purple',
  'pink',
  'orange',
  'teal',
  'cyan',
  'gray-dark',
  'gray',
  'blue',
  'red',
  'yellow',
  'green',
  'white'
);

// COMPONENT VARIABLE OVERRIDES
// For example, the following would override the cx-storefront background set
// in src/app/ui/layout/main/main.component.scss
// $cx-storefront-background-color: "info";
$spinWhileLoading: true !default;
$spinWhileWaiting: true !default;
$zoomThumbOnHover: true !default;
$overlayWhileLoading: true !default;
$useTabsOnPDP: true !default;
$useAccordionOnly: true !default;
