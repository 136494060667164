%cx-quick-order-form {
  .quick-order-form-container {
    display: flex;
    position: relative;
  }

  .quick-order-form-input {
    margin-inline-end: 15px;
    position: relative;
    width: 330px;

    .form-control {
      padding: 0 46px 0 12px;
    }

    input {
      height: 47px;
    }

    .quick-order-form-search-icon,
    .quick-order-form-reset-icon {
      background: transparent;
      border: none;
      color: var(--cx-color-medium);
      display: block;
      font-size: 24px;
      position: absolute;
      right: 6px;
      top: 4px;
    }

    .quick-order-form-reset-icon {
      cursor: pointer;
    }

    .list-limit-reached-text {
      bottom: -15px;
      color: var(--cx-color-danger);
      font-size: 12px;
      font-weight: normal;
      padding-inline-start: 2px;
      position: absolute;
    }
  }

  .quick-order-results {
    background-color: #fff;
    border-radius: 2px;
    border: 1px solid var(--cx-color-light);
    color: #000;
    display: none;
    font-size: 13px;
    position: absolute;
    top: 47px;
    width: 330px;
    z-index: 20;

    .quick-order-results-products {
      list-style: none;
      margin: 0px;
      padding: 0px;

      .quick-order-results-product-container {
        border-top: 1px solid var(--cx-color-light);

        &:first-of-type {
          border-top: none;
        }

        .quick-order-results-product {
          background-color: var(--cx-color-white);
          border: none;
          cursor: pointer;
          display: grid;
          grid-column-gap: 16px;
          padding: 10px;
          text-align: start;
          width: 100%;

          &.has-media {
            // create a grid layout in case we show an image
            grid-template-columns: 50px 1fr;
          }

          &:hover,
          &:focus,
          &.active {
            background-color: var(--cx-color-light);
          }

          .media {
            grid-row: 1/4;
          }

          .name {
            font-weight: bold;
          }
        }
      }
    }

    .quick-order-no-results {
      align-items: center;
      display: flex;
      font-size: 1rem;
      height: 37px;
      justify-content: flex-start;
      padding: 0 10px;
    }
  }
}

.quick-order-searchbox-is-active {
  .quick-order-results {
    display: block;
  }
}
