%cx-schedule-replenishment-order {
  .scaled-input {
    transform: scale(0.875);
  }

  .cx-label-container {
    display: flex;
    margin-bottom: 5px;

    cx-icon {
      font-size: $font-size-base * 1.3;
    }
  }

  .cx-order-replenishment-header {
    //TODO: (CXSPA-6306) - Remove feature flag next major release
    @include forFeature('a11yScheduleReplenishment') {
      @include type(5);
    }
    margin-inline-end: 14px;
    margin-bottom: 0;
  }

  .cx-order-type-card {
    font-size: $small-font-size;
    background-color: var(--cx-color-background);
    border: 1px solid $light;
    border-radius: var(--cx-buttons-border-radius);
    margin-bottom: 10px;
    padding: 20px 21px;

    .cx-order-type-container {
      margin-bottom: 7px;

      &:first-child {
        margin-top: 10px;
      }

      .form-check-label {
        top: 5px;
      }
    }

    .cx-replenishment-form-data-container {
      display: flex;
      flex-direction: row;
      margin-top: 20px;
      flex-wrap: wrap;
      @include media-breakpoint-up(md) {
        margin-inline-start: 2.375rem;
      }

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      .form-control {
        height: 32px;
        font-size: $small-font-size;
        padding: 0 9px;
      }

      .form-data-label {
        align-self: center;
        padding-inline-end: 10px;
        width: 100%;
      }

      .form-data-input {
        flex: 1;
      }

      .cx-days {
        margin-inline-end: 1.8rem;
      }

      .cx-dayMonth {
        margin-inline-start: 1.8rem;
      }

      .cx-day-of-month {
        width: 5rem;
      }

      .cx-month {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        max-width: 5.75rem;
        @include media-breakpoint-down(sm) {
          flex-grow: unset;
          width: 50%;
        }
      }

      &.cx-repeat-days-container {
        flex-direction: unset;
        flex-wrap: wrap;
        max-width: 280px;

        @include media-breakpoint-down(sm) {
          flex-direction: column;
        }

        .cx-repeat-days {
          //TODO: (CXSPA-6446) Remove feature flag next major release
          @include forFeature('a11yReplenishmentOrderFieldset') {
            font-size: inherit;
          }
          flex: 0 100%;
          margin-bottom: 14px;
          align-self: start;
        }

        .cx-week-day {
          margin-bottom: 0;
        }

        .form-check {
          flex: 0 50%;
          display: flex;
          align-items: center;
          margin-bottom: 13px;
        }
      }
    }
  }

  .cx-replenishment-date {
    input {
      padding-inline-start: 18px;
      padding-inline-end: 8px;
    }
  }
}
