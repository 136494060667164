%cx-order-return-request-list {
  .cx-order-history {
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding: 0;
    }
  }
  .cx-order-history-table {
    padding: 1.5rem 0 1.125rem 0;
    margin-bottom: 0;
    border-width: 1px 0 1px 0;
    border-color: var(--cx-color-light);
    border-style: solid;

    tr {
      width: 100%;

      @include media-breakpoint-down(sm) {
        border-width: 1px 0 0 0;
        border-color: var(--cx-color-light);
        border-style: solid;

        &:first-child {
          border-width: 0;
          padding: 1.25rem 0 0 0;
        }
      }
    }

    th {
      padding: 1.5rem 0 1.125rem 0;
      text-align: start;

      &:last-child {
        text-align: end;
      }
    }

    td {
      width: 25%;
      padding: 1.625rem 0;

      @include media-breakpoint-up(md) {
        text-align: start;

        &:last-child {
          text-align: end;
        }
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
        display: flex;
        border-width: 0;
        padding: 0 1.25rem;

        &:first-child {
          padding-top: 1.25rem;
        }

        &:last-child {
          padding-bottom: 1.25rem;
        }
      }
    }
  }

  .cx-order-history-header {
    padding: 40px 0 0 0;
    color: var(--cx-color-text);

    @include media-breakpoint-down(sm) {
      padding: 40px 20px 0 20px;
    }
  }

  .cx-order-history-thead-mobile {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .cx-order-history-code {
    text-decoration: underline;

    @include media-breakpoint-down(sm) {
      text-decoration: none;
    }
  }

  .cx-order-history-placed,
  .cx-order-history-status {
    text-align: center;
  }

  .cx-order-history-total {
    text-align: end;
  }

  @include media-breakpoint-down(sm) {
    .cx-order-history-placed,
    .cx-order-history-status,
    .cx-order-history-total {
      text-align: start;
    }
  }

  .cx-order-history-label {
    text-transform: uppercase;
    color: var(--cx-color-secondary);

    @include media-breakpoint-up(md) {
      display: none;
    }

    @include media-breakpoint-down(sm) {
      @include type('8');
      min-width: 110px;
    }
  }

  .cx-order-history-value {
    color: var(--cx-color-text);

    @include media-breakpoint-down(sm) {
      @include type('5');
      text-decoration: underline;
    }
  }

  .cx-order-history-form-group {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    width: 40%;

    span {
      margin-inline-end: 0.5rem;
      flex: none;
    }

    cx-sorting {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      padding: 1.25rem;
      width: 100%;
    }
  }

  .cx-order-history-sort {
    &.top {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding-top: 0;
      }
    }

    &.bottom {
      display: flex;
      justify-content: space-between;
      padding: 2rem 0 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        padding-top: 0;
      }
    }
  }

  .cx-order-history-pagination {
    @include media-breakpoint-down(sm) {
      margin: 0 auto;
    }
  }

  .cx-order-history-no-order {
    @include type('5');
    min-height: 415px;

    @include media-breakpoint-down(sm) {
      min-height: 474px;
      padding-inline-start: 1.25rem;
      padding-inline-end: 1.25rem;
    }

    .btn {
      margin: 1.25rem 0;
    }
  }
}
