%cx-order-details-items {
  margin-bottom: 15px;

  @include media-breakpoint-up(lg) {
    margin-top: 30px;
  }

  @include media-breakpoint-up(md) {
    margin-top: 10px;
  }

  .cx-list-header {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }

  .cx-list {
    @include media-breakpoint-down(sm) {
      margin-inline-end: 0;
      margin-inline-start: 0;
    }
  }

  .cx-list-status {
    margin: 0 0 0.5rem;
    padding: 15px 30px;
    background-color: var(--cx-color-background);
    @include type('4');
    font-weight: bold;
  }

  .cx-list-date {
    margin: 0.5rem 0 0;
    padding: 0 30px;
  }

  .cx-review-header {
    font-size: var(--cx-font-size, 1.125rem);
    font-weight: var(--cx-font-weight-semi);
    padding-inline-start: 30px;
  }

  .cx-pickup-order-consigned-entries-header {
    background-color: var(--cx-color-background);
    font-weight: var(--cx-font-weight-semi);
    font-size: var(--cx-font-size, 1.125rem);
    padding: 15px 40px;
    margin: 0px 0 30px 0;
  }
}
