%cx-configurator-cpq-overview-attribute {
  display: none;

  &:not(:empty) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-block-start: 15px;
    padding-block-end: 15px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }

    .cx-value-container {
      display: flex;
      flex-direction: row;
      width: 40%;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      .cx-thumbnail {
        @include cx-configurator-image();
      }

      .cx-value-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-inline-start: 15px;
        padding-inline-end: 15px;
        width: 80%;

        .cx-code {
          display: block;
          color: var(--cx-color-secondary);
          font-size: 0.875rem;
          overflow-wrap: anywhere;
          padding-block-end: 5px;
        }

        .cx-quantity,
        .cx-price {
          display: flex;
          flex-direction: row;

          .cx-identifier {
            width: 35%;
          }

          .cx-item {
            width: fit-content;
            line-break: anywhere;
          }
        }
      }
    }

    .cx-attribute-price-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      color: var(--cx-color-secondary);
      width: 60%;

      @include media-breakpoint-down(sm) {
        width: 100%;
        align-items: flex-start;
        padding-block-start: 5px;
      }

      .cx-attribute-label {
        padding-inline-end: 10px;
      }
    }
  }
}
