$cx-color-link-primary: #1672b7;

@import '@spartacus/styles/scss/core';
@import '@spartacus/styles/scss/cxbase/blocks/modal';
@import '@spartacus/styles/scss/misc/table';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/_mixins';

@import './styles/index';
