%cx-asm-main-ui {
  font-family: Arial, sans-serif;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;

  .cx-message {
    border-radius: 8px;
    margin-bottom: 0px;
    border-width: 1px;
    border-style: solid;

    .cx-message-content {
      margin-inline-start: 0;
      max-width: 100%;
      .cx-message-header {
        padding-inline-start: 10px;
        padding-inline-end: 1rem;
        line-height: 16px;
        text-align: start;
        min-height: 35px;
        .cx-message-text {
          color: #1d2d3e;
          font-size: 14px;
          font-family: '72';
          font-style: normal;
          font-weight: 400;
        }
      }
    }
    .close {
      font-size: 1rem;
      align-self: center;
      margin-inline-start: auto;
      position: unset;
      color: #0064d8;
    }
  }

  .cx-message-success {
    background-color: #f5fae5;
    border-color: #cee67e;
    .cx-message-icon {
      cx-icon {
        color: #30914c;
      }
    }
  }

  .close,
  .logout {
    cursor: pointer;
    padding: 0;
    flex: none;
    width: 16px;
    height: 16px;
    border: transparent;
    background-color: transparent;
  }

  .close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='black' d='M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z'/%3E%3C/svg%3E");
  }

  .logout {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3E%3Cpath fill='%230064D9' d='M11,2.7c1.2,0.6,2.2,1.5,2.9,2.6c1.3,1.9,1.5,4.4,0.6,6.5c-0.3,0.8-0.8,1.6-1.5,2.2c-0.6,0.6-1.4,1.1-2.2,1.5 C9.9,15.8,9,16,8,16c-0.9,0-1.9-0.2-2.7-0.5c-0.8-0.4-1.6-0.9-2.2-1.5c-0.6-0.6-1.1-1.4-1.5-2.2C0.7,9.6,0.9,7.2,2.1,5.3 c0.7-1.1,1.7-2,2.9-2.6v1.1C4.1,4.3,3.3,5.1,2.8,6C2.3,6.9,2,7.9,2,9c0,1.6,0.6,3.2,1.8,4.3c0.5,0.5,1.2,1,1.9,1.3 c1.5,0.6,3.2,0.6,4.7,0c0.7-0.3,1.4-0.7,1.9-1.3C13.4,12.1,14,10.6,14,9c0-1.1-0.3-2.1-0.8-3c-0.5-0.9-1.3-1.7-2.2-2.2 C11,3.8,11,2.7,11,2.7z M8,9C7.7,9,7.5,8.9,7.3,8.7C7.1,8.5,7,8.3,7,8V1c0-0.3,0.1-0.5,0.3-0.7c0.4-0.4,1-0.4,1.4,0 C8.9,0.5,9,0.7,9,1v7c0,0.3-0.1,0.5-0.3,0.7C8.5,8.9,8.2,9,8,9z'/%3E%3C/svg%3E%0A");
  }

  button[type='submit'] {
    padding: 0 12px;
    white-space: nowrap;
    border-radius: 4px;
    height: 36px;
    font-weight: 400;

    border-style: solid;
    border-width: 1px;

    &:disabled {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }

  &.hidden {
    display: none;
  }

  // Top Bar
  .asm-bar {
    color: #fff;
    background-color: #354a5f;
    height: 48px;
    display: flex;
    padding: 0 2rem;
    justify-content: space-between;
    z-index: 1;

    &-branding {
      display: flex;
      align-items: center;

      .logo {
        margin-inline-end: 8px;
      }

      .asm-title {
        font-size: 16px;
        font-weight: 700;
      }
    }

    &-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .cx-asm-customer-list {
        cursor: pointer;
        cx-icon {
          padding-top: 0;
          padding-inline-end: 7px;
          padding-bottom: 0;
          padding-inline-start: 0;
          color: #1e3050;
        }
        .cx-asm-customer-list-link {
          color: #0064d9;
          text-decoration: none;
        }
        .cx-asm-customer-list-link:hover {
          text-decoration: underline;
        }
      }
    }

    color: inherit;
    background-color: #ffffff;
    box-shadow: 2px 2px rgba(85, 107, 130, 0.1),
      inset 0px -1px 0px rgba(85, 107, 130, 0.2);
  }

  > *:nth-child(2) {
    padding: 1rem 2rem;
    display: flex;
    width: 100%;
  }

  // Input styles
  input {
    outline: 0;
    border: 1px solid #89919a;
    color: #32363a;
    background-color: #fff;
    border-radius: 4px;
    padding: 0 12px;
    height: 36px;

    &:focus {
      box-shadow: 0 0 0 1px #fafafa;
    }

    &:hover {
      border-color: #085caf;
    }
    &::placeholder {
      color: #74777a;
      font-style: italic;
    }
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: 575px) {
    .asm-bar-branding .asm-title {
      display: none;
    }

    .asm-alert {
      margin-top: 30px;
    }
  }

  .cx-message-info {
    background-color: #e1f4ff;
    border-color: #7bcfff;

    .cx-message-icon {
      cx-icon {
        color: #0070f2;
      }
    }

    .close {
      color: #0064d8;
    }
  }
}
