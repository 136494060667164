%cx-configurator-overview-filter-button {
  &:not(:empty) {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-block-start: 20px;
  }

  .cx-config-filter-button {
    margin-inline-start: 16px;
    margin-inline-end: 16px;

    @include media-breakpoint-down(md) {
      inline-size: 100%;
    }
  }

  cx-configurator-overview-filter-bar {
    margin-inline-start: 16px;
  }

  .cx-ghost-filter-button {
    width: 100px;

    @include media-breakpoint-down(md) {
      inline-size: 100%;
    }

    height: 50px;
    margin-inline-start: 16px;
    margin-inline-end: 16px;
    background-color: var(--cx-color-ghost);
    margin-block-end: 16px;
    top: -8px;
  }
}
