%cx-banner {
  // TODO: (CXSPA-6362) - Remove feature flag + redundant styles
  a {
    display: block;
    &:focus {
      position: relative;
      z-index: 1;
    }
  }

  cx-generic-link {
    display: block;
  }

  @include forFeature('a11yOrganizationsBanner') {
    position: relative;
    a {
      z-index: 1;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  }

  .no-link {
    display: block;
  }

  img {
    width: 100%;
    margin: var(--cx-margin);
  }

  p {
    font-size: 4vw;
    line-height: 5vw;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

    position: absolute;
    z-index: 10;
    padding: 5vw;
    color: #fff;

    width: 100%;
    text-align: center;
    margin: 0;
  }
}
