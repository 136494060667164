html[dir='rtl'] cx-breadcrumb nav span:not(:last-child):after {
  content: '\\';
}

%cx-breadcrumb {
  display: flex;
  flex-direction: column;

  color: currentcolor;
  background-color: var(--cx-color-background);

  padding: var(--cx-spatial-base);

  box-shadow: inset 0px 10px 7px -12px var(--cx-color-dark);

  @include media-breakpoint-up(lg) {
    box-shadow: none;
  }

  h1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    text-transform: capitalize;
  }

  nav {
    font-size: var(--cx-font-size, 0.875rem);
    padding: 5px 0;

    display: flex;
    justify-content: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ol {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      list-style: none;
      padding-inline-start: 0;
      margin: 0;

      li {
        display: flex;
        color: var(--cx-color-secondary);

        &:not(:last-child):after {
          content: '/';
        }
        a {
          text-transform: capitalize;
          color: inherit;
          padding: 0px 5px;
        }
      }
    }
  }
}
