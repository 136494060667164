%ProductListPageTemplate,
%ProductGridPageTemplate {
  max-width: var(--cx-page-width-max);
  margin: auto;
  display: flex;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
  .ProductLeftRefinements {
    max-width: 25%;
    padding-top: 60px;
    padding-inline-end: 15px;
    padding-bottom: 15px;
    padding-inline-start: 15px;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: none;
    }
  }
  .ProductListSlot,
  .ProductGridSlot {
    max-width: 75%;

    @include media-breakpoint-down(md) {
      width: 100%;
      max-width: none;
    }
  }
}
