cx-add-to-saved-cart {
  max-height: 70px;

  .cx-add-to-saved-cart-container {
    display: flex;
    justify-content: flex-start;
    margin-top: 3rem;

    .cx-action-link {
      &:nth-child(n + 2) {
        margin-inline-end: 0;
        &:before {
          content: '|';
          color: var(--cx-color-light);
          display: inline-block;
          text-decoration: none;
          margin-inline-start: 0.313rem;
          margin-inline-end: 0.313rem;
        }
      }
    }

    // TODO: (CXSPA-5709) Remove feature flag next major
    @include forFeature('a11yExpandedFocusIndicator') {
      .cx-action-link {
        &:nth-child(n + 2) {
          margin-inline-end: unset;
          position: relative;
          margin-inline-start: 1rem;
          &:before {
            display: unset;
            position: absolute;
            left: -0.75rem;
            margin-inline-start: unset;
            margin-inline-end: unset;
          }
        }
      }
    }

    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }

  a.disabled {
    color: gray;
    cursor: not-allowed;
    text-decoration: underline;
  }

  button {
    &:focus {
      @include visible-focus();
    }
  }
}
