%cx-configurator-attribute-drop-down {
  @include cx-configurator-attribute-type();
  @include cx-configurator-form-group();
  @include cx-configurator-attribute-level-quantity-price();

  flex-direction: column;

  @include forFeature('productConfiguratorAttributeTypesV2') {
    .cx-value-label-pair {
      width: 80%;

      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      line-break: normal;
      display: flex;
      flex-direction: column;

      cx-configurator-show-more {
        padding-block-start: 5px;
      }
    }
  }

  @include media-breakpoint-down(sm) {
    .cx-value-price {
      margin-block-start: 32px;
    }
  }

  .cx-attribute-level-quantity-price {
    margin-block-start: 32px;
  }

  .cx-configurator-attribute-additional-value {
    @include forFeature('productConfiguratorAttributeTypesV2') {
      width: 100%;
      padding-inline-end: 10px;
    }

    margin-inline-start: 0px;
  }
}
