%cx-store {
  .cx-store {
    display: flex;
    border-top: solid thin $gray-500;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
  }

  .cx-store-address {
    @include type('7');
    flex-grow: 1;

    .cx-store-name {
      @include type('5');
    }
    .cx-store-full-address {
      padding-top: 1rem;
      line-height: var(--cx-line-height, 1.125rem);
    }
  }

  .cx-store-distance {
    @include type('7');
    text-align: end;
  }

  .cx-store-opening-hours-toggle {
    @include type('6');
    color: $cx-color-link-primary;
    cursor: pointer;
    user-select: none;
    text-decoration: underline;
    .cx-store-opening-hours-icon {
      color: var(--cx-color-secondary);
      opacity: var(--cx-opacity, 0.5);
    }
    padding-inline-start: 0;
    padding-top: 0.5rem;
    border: none;
    background: none;
  }

  .cx-stock-level {
    @include type('6');
  }

  .cx-store-stock-icon {
    color: var(--cx-color-secondary);
    opacity: var(--cx-opacity, 0.5);
  }

  .cx-store-out-of-stock {
    color: var(--cx-color-danger);
  }

  .cx-store-in-stock {
    color: var(--cx-color-success);
  }

  .cx-store-pick-up-from-here {
    padding: 0 0 25px 0;
    vertical-align: middle;
  }
}
