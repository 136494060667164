@import './buttons';
@import './message';
@import './list';
@import './card';
@import './details';
@import './form';
@import './page-template';

cx-org-unit-list {
  display: contents;
}

cx-org-list {
  // https://yoksel.github.io/url-encoder/
  // <?xml version="1.0" encoding="utf-8"?>
  // <svg version="1.1"  viewBox="0 0 44.21 69.76" >
  //   <polyline fill="none" stroke="#D3D6DA" stroke-width="10" points="5,5 35,35 5,65 "/>
  // </svg>
  --list-bg: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 44.21 69.76'%3E%3Cpolyline fill='none' stroke='%23D3D6DA' stroke-width='10' points='5,5 35,35 5,65 '/%3E%3C/svg%3E%0A");
  --list-bg-pos: calc(100% - 26px) 50%;

  @extend %buttons !optional;
  @extend %organizationMessage !optional;

  @extend %organizationList !optional;
  @extend %organizationCard !optional;
  @extend %organizationItemDetails !optional;
  @extend %organizationItemForm !optional;
}

html[dir='rtl'] cx-org-list {
  --list-bg: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 44.21 69.76' %3E%3Cpolyline transform='scale (-1, 1)' transform-origin='center' fill='none' stroke='%23D3D6DA' stroke-width='10' points='5,5 35,35 5,65 '/%3E%3C/svg%3E");
  --list-bg-pos: 25px;
}

.my-company-popover {
  .popover-details {
    > .property {
      > label {
        font-weight: bold;
        margin-bottom: 0;
      }
      margin-bottom: $label-margin-bottom;
    }
  }
  .value {
    font-size: $font-size-base * 0.75;
  }
}

.hint-popover {
  font-size: 0.75rem;
  p {
    margin: 0;
  }
}
