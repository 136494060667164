@import '../../../theme';

%cx-add-to-home-screen-banner {
  .cx-add-to-home-screen-banner {
    background-color: var(--cx-color-background);
    padding: 20px;
    text-align: center;
    margin: 0 0 2.5rem;

    @include media-breakpoint-down(sm) {
      margin-top: 0;
      margin-inline-end: 1.25rem;
      margin-bottom: 2rem;
      margin-inline-start: 1.25rem;
    }

    ul {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 10px 40px;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
        margin-top: 0;
        margin-inline-end: auto;
        margin-bottom: 0;
        margin-inline-start: auto;
        max-width: 280px;
        padding-inline-start: 50px;
        padding-inline-end: 20px;
        padding-bottom: 20px;
        padding-top: 0;
      }

      li {
        min-width: 35%;
        text-align: start;
      }
    }
  }

  .cx-add-to-home-screen-banner-inner {
    max-width: 600px;
    margin-top: 0;
    margin-inline-end: auto;
    margin-bottom: 0;
    margin-inline-start: auto;
  }
}
