// Loading Animation
@keyframes loading {
  100% {
    transform: translateX(100%);
  }
}

:root {
  --cx-color-ghost: #f1f1f1;
  --cx-color-ghost-animation: rgba(255, 255, 255, 0.2);
  --cx-ghost-radius: 5px;
  --cx-ghost-margin: 10px;
}

.ghost {
  position: relative;

  color: var(--cx-color-ghost);

  &:before {
    display: block;
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);

    background: linear-gradient(
      90deg,
      transparent,
      var(--cx-color-ghost-animation),
      transparent
    );

    // Adding animation
    animation: loading 0.9s infinite;
  }
}
