%cx-product-list-item {
  padding-bottom: 1rem;

  .cx-product-search-list {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0 0 25px 0;

    &:last-of-type {
      border-width: 0 0 0 0;
    }
  }

  .cx-product-image-container {
    display: block;
    text-align: center;
    height: 100%;
  }

  .cx-product-image {
    width: 100%;
    height: 100%;
    display: block;
  }

  .cx-product-name {
    @include type('5');
    text-align: start;
    display: block;
    margin: 0 0 3px 0;
    color: var(--cx-color-text);
    text-decoration: none;

    h2 {
      @include type('5');

      @include media-breakpoint-down(sm) {
        min-height: 48px;
      }
    }

    &:hover {
      color: var(--cx-color-primary);
    }
  }

  // TODO: (CXSPA-5709) Remove feature flag next major
  @include forFeature('a11yExpandedFocusIndicator') {
    .cx-product-name {
      a {
        margin: 0 0 3px 0;
        color: var(--cx-color-text);
        text-decoration: none;
        @include type('5');
        @include media-breakpoint-down(sm) {
          text-align: center;
          min-height: 48px;
        }

        &:hover {
          color: var(--cx-color-primary);
        }
      }
    }
  }

  .cx-product-price {
    @include type('3');
    text-align: start;
    margin: 15px 0 0 0;

    &.old {
      color: var(--cx-color-secondary);
      text-decoration: line-through;
    }

    &.new {
      margin-top: 0;
      margin-inline-end: 0;
      margin-bottom: 0;
      margin-inline-start: 5px;
      color: var(--cx-color-primary);
    }
  }

  cx-media.is-missing {
    min-height: 255px;
  }

  cx-variant-style-icons {
    display: block;
    margin-top: 1rem;
  }

  @include media-breakpoint-down(sm) {
    .cx-product-search-list {
      padding-bottom: 15px;
    }

    .cx-product-image {
      max-height: 255px;
    }

    .cx-product-name,
    .cx-product-rating,
    .cx-product-price {
      text-align: center;
    }

    .cx-product-name {
      height: 2.5rem;
    }

    .cx-product-price {
      margin: 1rem 0;
    }

    .cx-product-no-review {
      text-align: center;
    }
  }
}
