%cx-item-counter {
  width: 146px;

  input {
    text-align: center;

    // avpid native increase/decrease buttons on the numeric field
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      appearance: none;
    }
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }
  &.readonly {
    button {
      display: none;
    }

    input {
      border: none;
      cursor: text;
      width: 65px;
    }
  }

  &:not(.readonly) {
    border-radius: 4px;
    border: solid 1px var(--cx-color-light);
    display: inline-flex;

    input {
      border: solid 1px var(--cx-color-light);
      border-width: 0 1px;
      padding: 12px 9px;
      max-height: 48px;
      min-width: 46%;
      max-width: 46%;

      &:focus {
        background: var(--cx-color-background);
      }
    }
  }

  button {
    background-color: var(--cx-color-transparent);
    border: none;
    color: var(--cx-color-text);
    cursor: pointer;
    margin: 0;
    max-height: 48px;
    min-width: 27%;
    max-width: 27%;

    @include type(3);

    &:hover {
      color: var(--cx-color-primary);
    }

    &:focus {
      color: var(--cx-color, var(--cx-color-primary));
      cursor: pointer;
      font-weight: var(--cx-font-weight-bold);
    }

    &:disabled {
      color: var(--cx-color-light);
    }
  }

  :disabled {
    cursor: not-allowed;
  }
}
