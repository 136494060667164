%cx-product-carousel-item {
  .actions {
    width: 60%;
    margin: auto;

    > *:not(:first-child) {
      margin-bottom: 4px;
    }
  }
}
