%cx-category-navigation {
  // all category headings in desktop have uppercase text, as
  // well as the root category links
  span,
  button:not(cx-generic-link + button),
  cx-navigation-ui > nav > ul > li > cx-generic-link {
    text-transform: uppercase;
    font-weight: 600;
    width: 100%;
  }

  li {
    list-style: none;
  }

  cx-navigation-ui > nav > ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    > li {
      @include media-breakpoint-up(lg) {
        padding-inline-end: 15px;
        padding-inline-start: 15px;
      }

      list-style: none;
      display: flex;

      @include media-breakpoint-up(lg) {
        > .wrapper {
          margin-top: $header-height;
        }
      }
    }
  }

  // we hide the categories in flyout mode on mobile
  cx-navigation-ui.flyout {
    @include media-breakpoint-down(md) {
      &.is-open {
        li.is-open:not(.back) {
          > span {
            display: none;
          }
        }
      }
    }
  }
}
