%cx-configurator-price {
  // TODO: this block should be remove after productConfiguratorAttributeTypesV2 feature flag has been removed
  // TODO: start of the block
  display: none;

  &:not(:empty) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .cx-quantity-price {
      font-size: var(--cx-font-small, 0.9rem);
      line-break: anywhere;
    }

    .cx-price,
    .cx-price-total {
      font-weight: bold;
      line-break: anywhere;
    }

    .cx-greyed-out {
      color: var(--cx-color-secondary);
    }
  }
  // TODO: end of the block

  @include forFeature('productConfiguratorAttributeTypesV2') {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .cx-quantity-price {
      font-size: var(--cx-font-small, 0.9rem);
      line-break: anywhere;
    }

    .cx-price,
    .cx-price-total {
      font-weight: bold;
      line-break: anywhere;
    }

    .cx-greyed-out {
      color: var(--cx-color-secondary);
    }
  }
}
