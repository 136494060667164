%cx-stock-notification {
  button.btn[aria-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  .stock-notification-notes {
    p {
      margin: 0 0 10px 0;
      a {
        @include type('6');
        color: var(--cx-color-dark);
        &:hover {
          color: var(--cx-color-primary);
        }
      }
    }
  }
}
