cx-import-export-order-entries {
  display: flex;
  justify-content: flex-start;
  margin: 1.5rem 0;
  max-width: var(--cx-page-width-max);
  > * {
    align-self: flex-start;
    &:before {
      content: '|';
      color: var(--cx-color-light);
      display: inline-block;
      text-decoration: none;
      margin-inline-start: 0.313rem;
      margin-inline-end: 0.313rem;
    }
    &:first-child {
      &:before {
        display: none;
      }
    }
  }
  cx-import-order-entries {
    width: auto;
    margin: 0;
  }
  // override 'container' class if 'cx-export-order-entries' is within 'cx-import-export-order-entries'
  cx-export-order-entries {
    width: auto !important;
    margin: 0 !important;
    padding: 0 !important;
    min-width: 0 !important;
  }
}
