@mixin cx-configurator-image {
  width: 3rem;
  height: 3rem;

  @include media-breakpoint-up(md) {
    height: 5rem;
    min-width: 5rem;
    width: 5rem;
  }

  cx-media {
    background-color: var(--cx-color-light);
    height: 100%;
    width: 100%;
  }

  img {
    object-fit: cover;
    object-position: center;
    height: 100%;
    width: 100%;
  }
}
