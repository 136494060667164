%cx-configurator-price-summary {
  &:not(:empty) {
    .cx-price-summary-container {
      max-width: 1140px;

      @include cx-configurator-footer-container();

      .cx-total-summary {
        @include cx-configurator-footer-container-item();

        @include media-breakpoint-up(md) {
          padding-inline-start: 16px;
          padding-inline-end: 16px;
        }

        @include media-breakpoint-down(sm) {
          padding-inline-start: 0px;
          padding-inline-end: 0px;
        }

        .cx-summary-row {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          padding-block-start: 10px;
          padding-block-end: 10px;

          .cx-label {
            flex-grow: 1;
          }

          .cx-amount {
            word-wrap: break-word;
            text-align: end;
            flex-grow: 1;
          }
        }

        .cx-total-price {
          border-block-start: 1px solid var(--cx-color-light);

          .cx-label {
            font-weight: bold;
          }
        }
      }
    }
  }
}
