.cx-my-account-v2-user {
  border: 1px solid var(--cx-color-medium);
  width: 250px;
  padding: 20px 5px 5px 25px;
  gap: 40px;
  height: 120px;
  margin: 2rem 0rem 0rem 0rem;

  .cx-name {
    .cx-sign-out {
      text-decoration: underline;
    }
  }
}
