%cx-configurator-overview-menu {
  &:not(:empty) {
    overflow-y: auto;

    ul {
      list-style-type: none;
      padding-inline-start: 0px;
      padding-block-start: 5px;
      padding-block-end: 0px;
      margin-block-end: 0px;

      .cx-menu-group {
        padding-block-start: 10px;
        margin-inline-start: 20px;
        list-style-type: none;

        .cx-menu-item {
          display: flex;
          flex-direction: row;
          text-decoration: none;
          color: var(--cx-color-text);
          border: none;
          background-color: transparent;
          transition: all 50ms ease-in-out 50ms; /*This small transition makes setting of the active state smooth */

          span {
            margin-inline-end: 5px;
          }

          cx-icon {
            visibility: hidden;
          }

          &.active,
          &:hover {
            color: var(--cx-color-primary);
          }

          &.active {
            cx-icon {
              visibility: visible;
            }
          }

          span {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            line-break: anywhere;
            @include cx-configurator-truncate-content();
          }
        }

        &.groupLevel1 {
          margin-inline-start: 0px;
        }
      }
    }
  }
}
