%buttons {
  @import 'bootstrap/scss/buttons';
  a.link,
  button.link {
    @extend .btn, .btn-link, .btn-sm;
    &:focus {
      @include visible-focus();
    }
    color: var(--cx-color-primary);

    padding: 0;
    display: flex;
    justify-content: left;
    align-items: center;
    text-decoration: none;
    font-size: 1rem;

    &:hover,
    &:focus {
      text-decoration: none;
    }

    &.disabled {
      color: var(--cx-color-text);
      cursor: not-allowed;
    }
  }

  button.button,
  a.button {
    @extend .btn, .btn-sm;

    &:focus {
      @include visible-focus();
    }

    &:focus,
    &.primary:focus,
    &.primary:focus:active,
    &:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }

    &.secondary {
      @extend .btn-secondary;
    }

    padding: var(--cx-spatial-base);
    margin: var(--cx-spatial-base);
    font-size: 1rem;
    line-height: 1.5rem;
    // TODO: (CXSPA-6457) - Remove feature flag next major release
    @include forFeature('a11yListOversizedFocus') {
      color: var(--cx-color-primary);
    }

    &.active {
      color: var(--cx-color-primary);
    }

    &.icon {
      padding: 0;
    }
  }

  a.link,
  button.link {
    &:hover {
      color: var(--cx-color-primary);
    }
  }
  a.button.primary,
  a.button.primary.disabled,
  a.button.primary:not(:disabled):not(.disabled),
  button.button.primary,
  button.button.primary.disabled,
  button.button.primary:active:not(:disabled):not(.disabled) {
    @extend .btn-primary;
    background-color: var(--cx-color-primary);
    border-color: var(--cx-color-primary);
    &:hover {
      background-color: var(--cx-color-primary-accent);
      border-color: var(--cx-color-primary-accent);
    }
  }

  .form-buttons {
    display: flex;
    padding: var(--cx-spatial-base);
    place-content: space-between;
    @include media-breakpoint-down(md) {
      flex-direction: column;
    }
    button,
    a {
      flex: 1;
      margin: var(--cx-spatial-base);
    }
  }
}
