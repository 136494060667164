@mixin cx-configurator-attribute-visible-focus {
  .form-check {
    input {
      &:focus {
        outline-width: var(--cx-visual-focus-width, 2px);
        outline: 2px auto var(--cx-color-visual-focus);
        outline-style: solid;
        outline-color: var(--cx-color-visual-focus);
        outline-offset: 4px;
        transition: none;
      }
    }
  }
}
