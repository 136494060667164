@import 'theme';

:root {
  @each $color, $value in $theme-colors {
    --cx-color-#{$color}: #{$value};
  }
  --cx-page-width-max: 1140px;

  --cx-font-weight-light: 300;
  --cx-font-weight-normal: 400;
  --cx-font-weight-semi: 600;
  --cx-font-weight-bold: 700;

  --cx-text-transform: capitalize;

  --cx-direction: #{$direction};

  --cx-transition-duration: 0.5s;
  --cx-animation-duration: 1s;

  --cx-border-radius: 1em;
  --cx-buttons-border-radius: 2rem;
  --cx-buttons-border-width: 3px;

  --cx-border-style: solid;

  body {
    text-align: start;
  }
}
