%cx-login-form {
  &.user-form {
    cx-spinner {
      display: none;
    }
    button {
      flex: 100%;
    }
  }
}
