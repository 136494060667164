@mixin cx-quote-toggle {
  .cx-toggle {
    cursor: pointer;
    user-select: none;
    margin-bottom: 1rem;

    cx-icon {
      padding-inline-end: 5px;
    }

    cx-icon,
    .cx-text {
      font-size: var(--cx-font-size, 1.125rem);
      font-weight: var(--cx-font-weight-semi);
    }
  }
}
