%cx-save-for-later {
  display: block;

  .cart-details-wrapper {
    padding: 2rem 0;
  }

  .cx-empty-cart-info {
    margin-top: 3rem;
  }
}
