%cx-password-visibility-toggle {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  inset-inline-end: 10px;
  display: flex;

  > button {
    font-size: 17px;
    margin: 0 !important;
    border: transparent;
    border-radius: 4px; // Needed to make focus border consistent with other inputs
    background: none;
    color: var(--cx-color-secondary);

    cx-icon {
      opacity: 0.8;
      width: 21px; // Need fixed width since alternating icons are not identical in width
    }
  }
}

.cx-password-input-wrapper {
  position: relative;
}
