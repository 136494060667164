%cx-product-attributes {
  table {
    width: 100%;
    margin: 0 0 30px 0;
    th {
      h2 {
        margin: 0 0 18px 0;
      }
    }
    tr {
      border-color: var(--cx-color-light);
      border-width: 1px 0 1px;
      border-style: solid;
    }
    td {
      padding: 12px 0 12px 0;
      vertical-align: top;
      width: 50%;
    }
    ul {
      list-style: none;
      padding: 0 0 0 0;
    }
  }
}
