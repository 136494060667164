%cx-configurator-textfield-add-to-cart-button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 15px;
  justify-content: flex-end;

  .cx-add-to-cart-btn {
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
  }
}
