cx-account-summary-document {
  .cx-account-summary-document {
    @include media-breakpoint-down(md) {
      max-width: 100%;
      padding: 0;
    }
  }

  .cx-account-summary-document-table {
    padding: 1.5rem 0 1.125rem 0;
    margin-bottom: 0;
    border-top: 1px solid var(--cx-color-light);
    border-bottom: 1px solid var(--cx-color-light);

    th {
      padding: 1.5rem 0 1.125rem 0;
      text-align: start;

      &:last-child {
        text-align: end;
      }
    }

    td {
      padding: 1.625rem 0;

      @include media-breakpoint-up(md) {
        text-align: start;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        display: flex;
        border-width: 0;
        padding: 0 1.25rem;

        &:first-child {
          padding-top: 1.25rem;
        }

        &:last-child {
          padding-bottom: 1.25rem;
        }
      }
    }
  }

  .cx-account-summary-document-header {
    padding: 40px 0 0 0;
    color: var(--cx-color-text);

    @include media-breakpoint-down(md) {
      padding-top: 40px;
      padding-inline-end: 20px;
      padding-bottom: 0;
      padding-inline-start: 20px;
    }
  }

  .cx-account-summary-document-thead-mobile {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .cx-account-summary-document-code {
    .cx-account-summary-document-value {
      text-decoration: none;
    }
  }

  .cx-account-summary-document-type {
    .cx-account-summary-document-value {
      text-decoration: none;
    }
  }

  .cx-account-summary-document-attachment {
    .cx-account-summary-document-value {
      text-decoration: underline;
      text-align: end;
      color: var(--cx-color-primary);
    }
  }

  .cx-account-summary-document-date {
    text-align: center;

    @include media-breakpoint-down(md) {
      text-align: start;
    }
  }

  .cx-account-summary-document-status {
    text-align: center;
    text-transform: capitalize;

    @include media-breakpoint-down(md) {
      text-align: start;
    }

    .status-open {
      color: var(--cx-color-success);
    }

    .status-closed {
      color: var(--cx-color-secondary);
    }
  }

  .cx-account-summary-document-monetary {
    text-align: end;

    @include media-breakpoint-down(md) {
      text-align: start;
    }
  }

  .cx-account-summary-document-label {
    color: var(--cx-color-secondary);

    @include media-breakpoint-up(lg) {
      display: none;
    }

    @include media-breakpoint-down(md) {
      @include type('8');
      min-width: 200px;
      min-height: 48px;
    }
  }

  .cx-account-summary-document-value {
    color: var(--cx-color-text);

    @include media-breakpoint-down(md) {
      @include type('5');
      font-weight: $font-weight-normal;
    }
  }

  .cx-account-summary-document-form-group {
    padding: 0;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    width: 40%;

    span {
      margin-inline-end: 0.5rem;
      flex: none;
    }

    cx-sorting {
      width: 100%;
    }

    @include media-breakpoint-down(md) {
      padding: 1.25rem;
      width: 100%;
    }
  }

  .cx-account-summary-document-sort {
    &.top {
      display: flex;
      justify-content: space-between;
      padding: 1rem 0;
      margin: 0;

      @include media-breakpoint-down(md) {
        flex-direction: column;
        padding-top: 0;
      }
    }

    &.bottom {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0 1rem 0;
      margin: 0;

      @include media-breakpoint-down(sm) {
        flex-direction: column;
      }
    }
  }

  .cx-account-summary-document-pagination {
    @include media-breakpoint-down(md) {
      margin: 0 auto;
    }
  }

  .cx-account-summary-document-no-document {
    display: flex;
    flex-wrap: wrap;
    @include type('5');
    font-weight: $font-weight-normal;
    min-height: 415px;
    padding-top: 2rem;
    border-top: 1px solid var(--cx-color-light);
    justify-content: center;

    @include media-breakpoint-down(sm) {
      min-height: 474px;
      padding-inline-start: 1.25rem;
      padding-inline-end: 1.25rem;
    }
  }

  .cx-account-summary-document-row {
    &:nth-child(odd) {
      @include media-breakpoint-down(md) {
        background-color: var(--cx-color-background);
      }
    }
  }

  .cx-account-summary-document-attachment-btn {
    color: var(--cx-color-primary);
    background-color: inherit;
    border: 0;
  }

  .cx-account-summary-document-attachment-icon {
    font-size: 1.4rem;
  }

  .cx-account-summary-document-attachment-text {
    font-weight: var(--cx-font-weight-bold);
    padding-inline-start: 0.5rem;
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}
