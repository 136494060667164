@mixin visible-focus {
  outline-style: solid;
  outline-color: var(--cx-color-visual-focus);
  outline-width: var(--cx-visual-focus-width, 2px);
  outline-offset: 4px;
  transition: none;

  // TODO: (CXSPA-5912) Remove feature flag next major
  @include forFeature('a11yMobileVisibleFocus') {
    @include media-breakpoint-down(md) {
      outline-offset: -2px;
      & > cx-media {
        transition: none;
        padding: 2px;
      }
    }
  }
}
