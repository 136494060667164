%progress-bar {
  &:before,
  &:after {
    height: 2px;
    width: 100%;
    left: 0;
    top: 0;
    margin: 0;
    content: '';
    position: fixed;
    z-index: 2;
  }
  &:before {
    background-color: var(--cx-color-light);
  }

  &:after {
    background-color: var(--cx-color-dark);
  }
}

%progress-bar-loading {
  @keyframes loading {
    0% {
      margin-inline-start: 0px;
      margin-inline-end: 100%;
    }
    50% {
      margin-inline-start: 25%;
      margin-inline-end: 0%;
    }
    100% {
      margin-inline-start: 100%;
      margin-inline-end: 0;
    }
  }

  &:after {
    animation: loading 2s cubic-bezier(0.4, 0, 0.2, 1) infinite;
  }
}

%progress-bar-loaded {
  @keyframes loaded {
    0% {
      margin-inline-start: 50%;
      margin-inline-end: 100%;
    }
    100% {
      margin-inline-start: 100%;
      margin-inline-end: 0;
    }
  }
  &:after {
    animation: loaded 0.5s;
  }
}
