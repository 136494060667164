%cx-configurator-previous-next-buttons {
  display: none;

  &:not(:empty) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-inline-start: 16px;
    padding-inline-end: 16px;
    padding-block-start: 16px;
    padding-block-end: 16px;

    @include media-breakpoint-down(sm) {
      padding-block-start: 20px;
      padding-block-end: 20px;
      padding-inline-start: 16px;
      padding-inline-end: 16px;
    }

    .btn-secondary {
      inline-size: 25%;

      @include media-breakpoint-down(sm) {
        inline-size: 45%;
      }
    }
  }
}
