.cx-agnostic-table {
  table {
    margin: 1rem 0;
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    padding: 1.25rem 0.1rem 1.25rem 0.5rem;
    vertical-align: middle;
    text-align: center;
    &:nth-of-type(1) {
      text-align: start;
    }
    &:nth-last-of-type(1) {
      text-align: end;
    }
  }

  tr {
    border-top: 1px solid var(--cx-color-medium);
    padding: 1.25rem 0;
  }

  th {
    vertical-align: bottom;
    text-transform: capitalize;
    text-align: center;
    color: var(--cx-color-secondary);
    font-weight: var(--cx-font-weight-bold);
    font-size: var(--cx-font-size, 0.875rem);
    white-space: nowrap;

    &:nth-last-of-type(1) {
      padding-inline-end: 16px;
    }
  }

  .cx-mobile-header {
    display: none;
  }

  .cx-mobile-only {
    display: none;
  }

  @include media-breakpoint-down(md) {
    table,
    tr,
    td {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;
    }

    td {
      align-items: center;
      padding: 0.313rem;
    }

    thead {
      display: none;
    }

    .cx-mobile-header {
      display: block;
      white-space: nowrap;
      text-align: start;
      font-weight: var(--cx-font-weight-bold);
      line-height: var(--cx-line-height, 1.2222222222);
      overflow-wrap: break-word;
      padding-inline-end: 1rem;
      min-width: 5rem;
    }

    .cx-mobile-only {
      display: flex;
    }
  }
}
