%LoginPageTemplate {
  padding-bottom: 3rem;

  .cx-section-title {
    margin: 40px 0 15px;
  }
  cx-page-slot {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: var(--cx-page-width-max);
    padding-top: 3rem;
    padding-inline-end: 1.5rem;
    padding-bottom: 0;
    padding-inline-start: 1.5rem;
    margin: auto;

    > * {
      max-width: var(--cx-max-width, 50%);
      width: 100%;
    }
  }

  @include media-breakpoint-down(sm) {
    --cx-max-width: 100%;

    button[type='button'] {
      margin-bottom: 1em;
    }
  }

  cx-login-register {
    @include media-breakpoint-up(md) {
      width: 50%;
      --cx-max-width: 50%;
    }
  }
}
