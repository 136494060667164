%cx-asm-customer-360-map {
  display: inline-grid;
  font-size: 0.8125rem;
  grid-gap: 12px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 1fr;
  margin: 0 auto;
  padding: 12px 28px 0;
  width: 100%;
  @media (max-width: 940px) {
    grid-template-columns: 1fr;
  }

  .bold {
    font-weight: 600;
  }

  .store-count {
    align-self: center;
    color: #6c7079;
    font-size: 1rem;
    grid-column: 1 / span 2;
    @media (max-width: 940px) {
      grid-column: 1;
    }
  }

  .store-details {
    display: flex;
    flex-direction: row;
    margin-bottom: 12px;

    &-info {
      margin: 0 0 0 8px;
    }
  }

  .store-listing-item {
    display: flex;
    flex-direction: row;
    padding: 12px 0;
    position: relative;
    width: 100%;
    border: 0;
    text-align: start;

    &.selected {
      background: #d1efff;
      border-bottom: 1px solid #d3d6db;
    }

    &:not(.selected) {
      &:hover {
        background: #d2dbe6;
      }

      &:not(:hover) {
        &:nth-of-type(2n + 1) {
          background: #ebf8ff;
          border-bottom: 1px solid #0070f2;
        }

        &:nth-of-type(2n) {
          background: white;
          border-bottom: 1px solid #e5e5e5;
        }
      }
    }

    &-details {
      line-height: 16px;
      padding: 0 8px;
    }

    &-distance {
      align-items: center;
      box-sizing: border-box;
      display: flex;
      flex: 0 0 100px;
      justify-content: center;
      padding: 0 4px;
      margin: 0 0 0 auto;
    }
  }
  .store-map-container {
    height: 400px;
    .store-map {
      border: 0;
      border-bottom: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      padding: 12px 0;
      width: 100%;
      height: 100%;
    }
  }

  .store-openings {
    font-size: 0.875rem;

    &-date {
      align-items: center;
      display: flex;
      flex-direction: row;
    }

    &-dates {
      margin-bottom: 24px;
    }

    &-day {
      display: inline-block;
      margin: 0 4px 0 0;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100px;
    }
  }
}
