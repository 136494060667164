%cx-close-account {
  display: flex;
  justify-content: center;

  .cx-info {
    margin: 0 0 3em 0;
  }

  .cx-btn-group {
    button {
      min-width: 100%;
      text-transform: lowercase;
      &:first-line {
        text-transform: capitalize;
      }

      &:first-child {
        margin-inline-end: 1em;
      }
    }

    @include media-breakpoint-down(sm) {
      a:first-child {
        margin: 0 0 1em 0;
      }
    }
  }

  ul {
    padding-inline-start: 20px;
  }
}
