@mixin cx-configurator-attribute-selection-image {
  @include cx-configurator-attribute-type();

  .cx-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;

    .cx-configurator-select {
      width: 200px;
      padding-inline-start: 5px;
      padding-inline-end: 5px;
      padding-block-start: 5px;
      padding-block-end: 5px;

      input[type='checkbox'].form-input,
      input[type='radio'].form-input {
        opacity: 0;
        position: absolute;

        &:focus:not(:focus-visible) + .cx-label-container {
          outline: none; //remove visible focus when clicking by a mouse
        }

        &:focus + .cx-label-container {
          @include visible-focus();
        }
      }

      // TODO: this block should be remove after productConfiguratorAttributeTypesV2 feature flag has been removed
      // TODO: start of the block
      input[checked='checked'] + .cx-label-container {
        .cx-img,
        .cx-img-dummy {
          border: var(--cx-color-primary) 3px solid;
        }
      }
      // TODO: end of the block

      .cx-label-container {
        margin-inline-start: 5px;
        margin-inline-end: 5px;
        margin-block-start: 5px;
        margin-block-end: 5px;

        label {
          // TODO: this block should be remove after productConfiguratorAttributeTypesV2 feature flag has been removed
          // TODO: start of the block
          cursor: pointer;
          // TODO: end of the block

          text-align: center;
          line-break: anywhere;

          button {
            border: none;
            background-color: inherit;
            padding-inline-start: 0;
            padding-inline-end: 0;

            cx-icon {
              pointer-events: none;
              color: var(--cx-color, var(--cx-color-secondary));
            }
          }

          .cx-img,
          .cx-img-dummy {
            display: block;
            border-radius: 2px;
            border: transparent 3px solid;
            padding-inline-start: 3px;
            padding-inline-end: 3px;
            padding-block-start: 3px;
            padding-block-end: 3px;
            margin-inline-start: auto;
            margin-inline-end: auto;
          }

          .cx-img-dummy {
            block-size: 75%;

            @include forFeature('productConfiguratorAttributeTypesV2') {
              max-width: 100px;
              min-height: 100px;
            }
          }

          @include forFeature('productConfiguratorAttributeTypesV2') {
            .cx-img-hover:hover {
              border: var(--cx-color-secondary) 3px solid;
            }

            .cx-img-selected {
              border: var(--cx-color-primary) 3px solid;
            }
          }
        }

        cx-configurator-price {
          @include forFeature('productConfiguratorAttributeTypesV2') {
            min-height: 27px;
            max-width: 100%;
          }
          align-items: center;
        }
      }
    }
  }
}
