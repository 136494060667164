%cx-order-confirmation-thank-you-message {
  .cx-order-confirmation-message {
    text-align: var(--cx-text-align, center);
    padding: var(--cx-padding, 2.5rem);

    h2 {
      font-weight: var(--cx-font-weight-bold);
      font-size: var(--cx-font-size, 1.5rem);
    }

    .btn-link {
      font-size: $small-font-size;
      font-weight: $font-weight-bold;
      text-transform: var(--cx-text-transform, uppercase);
    }
  }

  .cx-page-title {
    color: var(--cx-color, var(--cx-color-text));
  }
}
