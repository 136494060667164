%cx-configurator-attribute-header {
  display: flex;
  flex-direction: column;
  margin-inline-start: 17px;
  margin-inline-end: 17px;
  margin-block-end: 0px;

  label {
    @include type('5');
    padding-block-start: 10px;
  }

  .cx-required-icon {
    &:after {
      content: '*';
      color: var(--cx-color-danger);
    }
  }

  @include cx-configurator-required-error-msg();

  .cx-conflict-msg,
  .cx-hidden-msg {
    display: inline-flex;

    cx-icon {
      color: var(--cx-color-warning);
      font-size: 20px;
      padding-inline-end: 5px;
    }
  }

  .cx-conflict-msg {
    .cx-action-link {
      font-size: 14px;
      min-height: 0px;
    }
  }

  a.cx-conflict-msg {
    cursor: pointer;
  }

  a.cx-action-link {
    cursor: pointer;
  }

  img {
    width: 25%;
    padding-block-end: 5px;
    padding-block-start: 5px;
    display: block;
  }
}
