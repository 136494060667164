%cx-product-carousel {
  display: flex;
  a {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    padding-bottom: 10px;
    color: var(--cx-color-text);
    outline: none;
    h3 {
      @include type('8');
      height: auto;
      min-height: 30px;
      margin-top: 5px;
    }
    &:hover,
    &:focus {
      color: var(--cx-color-primary);
      text-decoration: underline;
    }
    &:focus {
      position: relative;
      z-index: 1;
    }
  }
  cx-media {
    align-self: center;
    width: 60%;

    &.is-missing {
      min-height: 255px;
      align-self: auto;
      width: auto;
    }
  }
}
