%cx-product-grid-item {
  display: flex;
  flex-direction: column;
  .cx-product-search-list {
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: var(--cx-color-light);
    margin: 0 0 25px 0;

    &:last-of-type {
      border-width: 0 0 0 0;
    }
  }

  cx-media.is-missing {
    min-height: 255px;
  }

  .cx-product-image-container {
    display: block;
    text-align: center;
  }

  .cx-product-image {
    width: 100%;
    margin: 3rem 0;
  }

  .cx-product-rating {
    text-align: center;
  }

  .cx-product-name {
    @include type('5');
    text-align: center;
    margin: 0 0 3px 0;
    color: var(--cx-color-text);
    text-decoration: none;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp: 2;
    max-width: 100%;
    height: 40px;
    display: block;

    &:hover {
      color: var(--cx-color-primary);
    }
  }

  .cx-product-price-container {
    flex-grow: 1;
    .cx-product-price {
      @include type('3');
      text-align: center;
      margin: 1rem 0;

      &.old {
        color: var(--cx-color-secondary);
        text-decoration: line-through;
      }

      &.new {
        margin: 0 0 0 5px;
        color: var(--cx-color-primary);
      }
    }
  }
}
