// Configure button needs a margin enforce a distance to predecessor buttons
%cx-configure-product {
  &:not(:empty) {
    .btn-block {
      margin-block-start: 10px;
    }
  }
}

cx-page-slot {
  &.Summary {
    %cx-configure-product {
      @include media-breakpoint-up(lg) {
        grid-column: 2;
        grid-row: 4;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        padding-block-end: 0px;
      }
    }
  }
}
