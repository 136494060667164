@mixin cx-ghost-bar {
  background-color: var(--cx-color-ghost);

  @include media-breakpoint-down(sm) {
    height: 35px;
  }

  @include media-breakpoint-up(md) {
    height: 25px;
  }
}
