%cx-pickup-option-dialog {
  background-color: rgba(0, 0, 0, 0.5);

  .cx-pickup-option-dialog {
    align-items: normal;

    @extend .modal-dialog;
    @extend .modal-dialog-centered;
    @include media-breakpoint-down(md) {
      height: 100%;
    }

    .cx-dialog-content {
      @include media-breakpoint-down(md) {
        height: 100%;
      }

      @extend .modal-content;
      .cx-dialog-header {
        .cx-dialog-title {
          @include type('3');
          margin-inline-start: var(--cx-margin-left, 0.875rem);
        }
      }

      .cx-dialog-header {
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
        margin-top: 1.15rem;
      }

      .cx-dialog-body {
        overflow-y: scroll;
      }
    }
    .modal-body {
      padding-top: 0.75rem;
    }
  }
}
