%cx-active-facets {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;

  color: var(--cx-color-text);

  div:first-child {
    flex: 100%;
    padding: 0 0 10px 0;
    margin: 0 0 20px 0;
    font-size: 1.125rem;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  a {
    display: flex;
    align-items: center;
    transition: all 0.3s;
    min-width: 0;
    color: var(--cx-color-text);
    font-size: var(--cx-font-size, 0.92rem);
    background: var(--cx-color-background);

    &:hover {
      background: var(--cx-color-light);
      color: var(--cx-color-primary);
      text-decoration: none;
    }

    border-radius: 4px;
    margin-top: 0;
    margin-inline-end: 10px;
    margin-bottom: 10px;
    margin-inline-start: 0;

    > span {
      margin-inline-start: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    cx-icon {
      font-size: 1rem;
      padding: 10px;
      &:hover {
        color: var(--cx-color-primary);
      }
    }
  }
}
