@mixin button-reset {
  margin: 0;
  padding: 0;
  // font-smoothing: antialiased; left here for the future
  appearance: none;
  outline: 0;
  border: 0;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  background-color: transparent;
}
