%cx-cart-validation-warnings {
  cx-icon {
    font-size: 20px;
  }

  .alert-icon {
    margin-inline-end: var(--cx-margin-right, 1rem);
  }

  .alert {
    justify-content: flex-start;
    text-align: var(--cx-text-align, left);

    .close {
      right: 1.75rem;
    }
  }
}
