%cx-address-book {
  .cx-address-deck {
    padding: 1.25rem 0 0 0;
  }

  .cx-address-deck-spinner {
    padding: 5rem 0 5rem 0;
  }

  .cx-form {
    padding-top: 30px;
  }

  .cx-section-msg {
    margin-bottom: 1.5rem;
  }

  .cx-address-card {
    padding-bottom: 30px;
  }
}
